import { apiCloudFunctionMap } from 'application-flow/enums/api/apiCloudFunctionMap';
import { ApiUrl } from 'application-flow/enums/api/apiUrl';
import { Environment } from 'application-flow/enums/api/environment';
import { RequestPaths } from 'application-flow/enums/api/requestPaths';

export const getBaseUrl = (requestPath: RequestPaths) => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case Environment.Local:
      return mapLocalApiToBaseUrl(requestPath);
    case Environment.Development:
      return ApiUrl.Development;
    case Environment.Production:
      return ApiUrl.Production;
    default:
      return mapLocalApiToBaseUrl(requestPath);
  }
};

const mapLocalApiToBaseUrl = (requestPath: RequestPaths) => {
  const base = ApiUrl.Local;
  const cloudFunction = getCloudFunctionValue(requestPath);
  const url = `${base}/${cloudFunction}`;
  return url;
};

const getCloudFunctionValue = (requestPath: RequestPaths) => {
  const cloudFunction = apiCloudFunctionMap[requestPath];
  if (!cloudFunction) {
    throw new Error(
      `Unable to map api request path to cloud function. Request path ${requestPath} does not exist in apiCloudFunctionMap.`
    );
  }
  return cloudFunction;
};
