import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GetUserResponsesQuery,
  GetUserResponsesQueryVariables,
  UpsertResponseMutation,
  UpsertResponseMutationVariables
} from 'application-flow/generated/graphql';
import { IAnswer, IQuestionResponse, QuestionType } from 'application-flow/types/questions.types';
import { v4 as uuidv4 } from 'uuid';

export const BASE_PROGRESS_PERCENTAGE = 5;
export const LOGIN_PROGRESS_PERCENTAGE = 10;

interface IUseResponses {
  responses: IQuestionResponse[];
  numberOfNonDraftResponses: number;
  isResponsesLoading: boolean;
  findResponseByQuestionId: (questionId?: string) => IQuestionResponse | undefined;
  upsertResponse: (input: IUpsertResponseInput) => Promise<void>;
}

export const useResponses = (applicationId?: string): IUseResponses => {
  const { data: getUserResponses, loading: isResponsesLoading } = useQuery<
    GetUserResponsesQuery,
    GetUserResponsesQueryVariables
  >(GET_USER_RESPONSES, {
    variables: {
      applicationId: applicationId as string
    },
    skip: !applicationId
  });
  const [upsertResponseMutation] = useMutation<UpsertResponseMutation, UpsertResponseMutationVariables>(
    UPSERT_RESPONSE,
    {
      refetchQueries: [GET_USER_RESPONSES, 'GetUserResponses'],
      context: {
        headers: {
          'X-Hasura-Role': 'user'
        }
      }
    }
  );

  const responses = getUserResponses?.responses.map(mapQuestionResponseRow) || [];
  const numberOfNonDraftResponses = responses.filter((response) => !response.isDraft).length;

  const findResponseByQuestionId = (questionId?: string): IQuestionResponse | undefined => {
    if (!questionId) return;
    return responses.find((response) => response.questionId === questionId);
  };

  const upsertResponse = async ({
    questionId,
    answer,
    isDraft,
    updatedQuestionsHistory,
    questionTitle
  }: IUpsertResponseInput): Promise<void> => {
    if (!applicationId) throw new Error('Cannot upsert a response with no active application.');
    const currentResponse = findResponseByQuestionId(questionId);
    await upsertResponseMutation({
      variables: {
        id: currentResponse?.id || uuidv4(),
        applicationId: applicationId,
        questionId: questionId,
        questionsHistory: updatedQuestionsHistory,
        response: answer,
        isDraft,
        questionTitle
      }
    });
  };

  return {
    responses,
    isResponsesLoading,
    numberOfNonDraftResponses,
    findResponseByQuestionId,
    upsertResponse
  };
};

/************************************************
 * GraphQL Queries & Mutations
 ************************************************/

export const GET_USER_RESPONSES = gql`
  query GetUserResponses($applicationId: uuid!) {
    responses: af_question_response(order_by: { created_at: asc }, where: { application_id: { _eq: $applicationId } }) {
      id
      question_id
      response
      application_id
      is_draft
      user_id
      updated_at
      created_at
      af_question {
        section_id
        type
      }
    }
  }
`;

export const UPSERT_RESPONSE = gql`
  mutation UpsertResponse(
    $id: uuid!
    $applicationId: uuid!
    $isDraft: Boolean = false
    $response: jsonb!
    $questionId: uuid!
    $questionsHistory: jsonb!
    $questionTitle: String!
  ) {
    response: insert_af_question_response_one(
      object: {
        application_id: $applicationId
        is_draft: $isDraft
        response: $response
        question_id: $questionId
        updated_at: "now()"
        id: $id
        original_question_title: $questionTitle
      }
      on_conflict: {
        constraint: af_question_response_pkey
        update_columns: [response, is_draft, original_question_title]
      }
    ) {
      id
    }
    application: update_af_application_by_pk(
      pk_columns: { id: $applicationId }
      _set: { questions_history: $questionsHistory }
    ) {
      questions_history
      id
    }
  }
`;

/************************************************
 * Types & Interfaces
 ************************************************/

export interface IHasuraQuestionResponse {
  id: string;
  user_id: string;
  application_id: string;
  question_id: string;
  is_draft: boolean;
  response: IAnswer;
  updated_at: string;
  created_at: string;
  af_question: {
    section_id: string;
  };
}
export interface IHasuraQuestionResponseInput {
  application_id: string;
  question_id: string;
  is_draft: boolean;
  response: IAnswer;
}

interface IUpsertResponseInput {
  questionId: string;
  answer: IAnswer;
  isDraft: boolean;
  questionTitle: string;
  updatedQuestionsHistory: string[];
}

/************************************************
 * Helper Functions
 ************************************************/

const mapQuestionResponseRow = (questionResponseRow: GetUserResponsesQuery['responses'][0]): IQuestionResponse => {
  return {
    id: questionResponseRow.id,
    userId: questionResponseRow.user_id,
    applicationId: questionResponseRow.application_id,
    questionId: questionResponseRow.question_id,
    isDraft: questionResponseRow.is_draft,
    response: questionResponseRow.response,
    updatedAt: new Date(questionResponseRow.updated_at),
    createdAt: new Date(questionResponseRow.created_at),
    sectionId: questionResponseRow.af_question.section_id || undefined,
    questionType: questionResponseRow.af_question.type as QuestionType
  };
};
