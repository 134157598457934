import { gql, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CreateGhApplicationOnCandidateMutation,
  CreateGhApplicationOnCandidateMutationVariables,
  CreateGhCandidateAndApplicationMutation,
  CreateGhCandidateAndApplicationMutationVariables,
  GetQuestionFlowFromProgramIdQuery,
  GetQuestionFlowFromProgramIdQueryVariables,
  Get_User_ApplicationsQuery,
  SubmitApplicationMutation,
  SubmitApplicationMutationVariables,
  SubmitApplicationToGreenhouseMutation,
  SubmitApplicationToGreenhouseMutationVariables,
  TransferApplicationToGreenhouseJobMutation,
  TransferApplicationToGreenhouseJobMutationVariables,
  UpdateApplicationQuestionFlowMutation,
  UpdateApplicationQuestionFlowMutationVariables,
  UpdateApplicationQuestionHistoryMutation,
  UpdateApplicationQuestionHistoryMutationVariables
} from 'application-flow/generated/graphql';
import { useCurrentUser } from 'application-flow/hooks/useCurrentUser';
import { getGreenhouseStatus } from 'application-flow/services/api/httpApi.service';
import { IApplication } from 'application-flow/types/application.types';
import { IPhoneNumberAnswer, IQuestionResponse, ISingleSelectAnswer } from 'application-flow/types/questions.types';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

export const DEFAULT_PROGRAM_ID = process.env.NEXT_PUBLIC_DEFAULT_PROGRAM_ID as string;
export const DEFAULT_QUESTION_FLOW_ID = process.env.NEXT_PUBLIC_DEFAULT_QUESTION_FLOW_ID as string;
if (!DEFAULT_PROGRAM_ID) throw new Error('Missing NEXT_PUBLIC_DEFAULT_PROGRAM_ID env variable');
if (!DEFAULT_QUESTION_FLOW_ID) throw new Error('Missing NEXT_PUBLIC_DEFAULT_QUESTION_FLOW_ID env variable');

interface IUseApplications {
  allApplications: IApplication[];
  isApplicationsLoading: boolean;
  activeApplication: IApplication | undefined;
  submittedApplications: IApplication[];
  greenhouseStatusMap: Record<string, GreenhouseStatus>;
  hasExistingDraftApplication: boolean;
  canSubmitNewApplication: boolean;
  isGreenhouseStatusLoading: boolean;
  createNewApplication: () => void;
  submitActiveApplication: (responses: IQuestionResponse[]) => Promise<void>;
  updateQuestionHistory: (questionsHistory: string[], questionsToDraft: string[]) => Promise<void>;
  updateQuestionFlowByProgramId: (programId: string) => Promise<void>;
  transferApplicationToGreenhouseJob: (selectedProgramId: string) => Promise<void>;
  deleteActiveApplication: () => Promise<void>;
  createGreenhouseCandidateAndApplication: ({
    firstName,
    lastName
  }: {
    firstName: string;
    lastName: string;
  }) => Promise<void>;
  createGreenhouseApplicationOnCandidate: ({
    greenhouseCandidateId
  }: {
    greenhouseCandidateId: string;
  }) => Promise<void>;
}

export const useApplications = (): IUseApplications => {
  const router = useRouter();
  const client = useApolloClient();
  const { currentUser, isCurrentUserLoading } = useCurrentUser();
  const [greenhouseStatusMap, setGreenhouseStatusMap] = useState<Record<string, GreenhouseStatus>>({});
  const [isGreenhouseStatusLoading, setIsGreenhouseStatusLoading] = useState(true);
  const { data: getUserApplications, loading: isGetUserApplicationsLoading } = useQuery<Get_User_ApplicationsQuery>(
    GET_USER_APPLICATIONS,
    {
      variables: {
        userId: currentUser?.id
      },
      skip: !currentUser
    }
  );

  const [getQuestionFlowFromProgramId] = useLazyQuery<
    GetQuestionFlowFromProgramIdQuery,
    GetQuestionFlowFromProgramIdQueryVariables
  >(GET_QUESTION_FLOW_FROM_PROGRAM_ID);

  const [insertNewApplication] = useMutation<IInsertNewApplicationData, IInsertNewApplicationVars>(
    INSERT_NEW_APPLICATION,
    {
      refetchQueries: [GET_USER_APPLICATIONS, 'GET_USER_APPLICATIONS'],
      context: {
        headers: {
          'X-Hasura-Role': 'user'
        }
      }
    }
  );
  const [submitApplication] = useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(
    SUBMIT_APPLICATION,
    {
      context: {
        headers: {
          'X-Hasura-Role': 'user'
        }
      }
    }
  );

  const [submitApplicationToGreenhouse] = useMutation<
    SubmitApplicationToGreenhouseMutation,
    SubmitApplicationToGreenhouseMutationVariables
  >(SUBMIT_APPLICATION_TO_GREENHOUSE, {
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });

  const [updateApplicationQuestionHistory] = useMutation<
    UpdateApplicationQuestionHistoryMutation,
    UpdateApplicationQuestionHistoryMutationVariables
  >(UPDATE_APPLICATION_QUESTION_HISTORY);

  const [updateQuestionFlowMutation] = useMutation<
    UpdateApplicationQuestionFlowMutation,
    UpdateApplicationQuestionFlowMutationVariables
  >(UPDATE_APPLICATION_QUESTION_FLOW);

  const [deleteApplicationMutation] = useMutation<IDeleteApplicationData, IDeleteApplicationVars>(DELETE_APPLICATION, {
    refetchQueries: [GET_USER_APPLICATIONS, 'GET_USER_APPLICATIONS']
  });
  const [createGHCandidateAndApplication] = useMutation<
    CreateGhCandidateAndApplicationMutation,
    CreateGhCandidateAndApplicationMutationVariables
  >(CREATE_GH_CANDIDATE_AND_APPLICATION, {
    refetchQueries: [GET_USER_APPLICATIONS, 'GET_USER_APPLICATIONS']
  });
  const [createGHCandidateOnApplication] = useMutation<
    CreateGhApplicationOnCandidateMutation,
    CreateGhApplicationOnCandidateMutationVariables
  >(CREATE_GH_APPLICATION_ON_CANDIDATE, {
    refetchQueries: [GET_USER_APPLICATIONS, 'GET_USER_APPLICATIONS']
  });

  const [transferApplicationToGreenhouseJobMutation] = useMutation<
    TransferApplicationToGreenhouseJobMutation,
    TransferApplicationToGreenhouseJobMutationVariables
  >(TRANSFER_APPLICATION_TO_GREENHOUSE_JOB);

  const allApplications = getUserApplications?.af_application.map(mapApplicationRow) || [];
  const activeApplication = allApplications?.find((app) => !app.isSubmitted);
  const submittedApplications = allApplications?.filter((app) => app.isSubmitted) || [];
  const isApplicationsLoading = isGetUserApplicationsLoading || isCurrentUserLoading;
  const hasExistingDraftApplication = !isApplicationsLoading && !!currentUser && !!activeApplication;
  const canSubmitNewApplication =
    !isApplicationsLoading &&
    !!currentUser &&
    !Object.values(greenhouseStatusMap).some((status) => status === 'Awaiting Review' || status === 'Reviewing');

  useEffect(() => {
    setIsGreenhouseStatusLoading(true);
    if (isGetUserApplicationsLoading) return;
    fetchGreenhouseStatuses()
      .then((newGreenhouseStatusMap) => {
        setGreenhouseStatusMap(newGreenhouseStatusMap);
        setIsGreenhouseStatusLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch greenhouse statuses', error);
        setIsGreenhouseStatusLoading(false);
      });
  }, [isGetUserApplicationsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const queryClient = useQueryClient();

  const fetchGreenhouseStatuses = async () => {
    const applicationIds = submittedApplications.map(
      (app) => (app.isCofounderApplication ? app.primaryApplicationId : app.id) as string
    );

    const getStatuses = await Promise.all(
      applicationIds.map((id) =>
        queryClient.fetchQuery(['getGreenhouseStatus', { applicationId: id }], () => getGreenhouseStatus(id))
      )
    );

    const greenhouseStatusMap: Record<string, GreenhouseStatus> = {};
    getStatuses.forEach((data, index) => {
      const applicationId = applicationIds[index];
      if (data?.status) greenhouseStatusMap[applicationId] = data.status;
    });
    return greenhouseStatusMap;
  };

  const createNewApplication = async () => {
    if (!isGetUserApplicationsLoading && activeApplication)
      throw new Error('Cannot create new application while there is an active application.');

    // TODO: Check if one of the pastApplications haven't been processed yet and throw an error if so

    const programIdFromURL = router.query.programId as string;
    const { data } = await getQuestionFlowFromProgramId({ variables: { program_id: programIdFromURL } });

    const canChangeQuestionFlow =
      !!programIdFromURL && !!data && data?.program[0].is_disabled === false && data?.program[0].is_archived === false;

    await insertNewApplication({
      variables: {
        questionFlowId: canChangeQuestionFlow ? data?.program[0].af_question_flows[0].id : DEFAULT_QUESTION_FLOW_ID
      }
    });
  };

  const createGreenhouseCandidateAndApplication = async ({
    firstName,
    lastName
  }: {
    firstName: string;
    lastName: string;
  }) => {
    if (!activeApplication) throw new Error('Cannot create greenhouse candidate with no active application.');

    await createGHCandidateAndApplication({
      variables: {
        firstName: firstName,
        lastName: lastName,
        applicationId: activeApplication.id
      }
    });
  };

  const createGreenhouseApplicationOnCandidate = async ({
    greenhouseCandidateId
  }: {
    greenhouseCandidateId: string;
  }) => {
    if (!activeApplication) throw new Error('Cannot create greenhouse application with no active application.');

    await createGHCandidateOnApplication({
      variables: {
        greenhouseCandidateId: greenhouseCandidateId,
        applicationId: activeApplication.id
      }
    });
  };

  const updateQuestionHistory = async (questionsHistory: string[], questionsToDraft: string[]) => {
    if (!activeApplication) throw new Error('Cannot update question history with no active application.');

    await updateApplicationQuestionHistory({
      variables: {
        applicationId: activeApplication.id,
        questionsHistory: questionsHistory,
        draftQuestionIDs: questionsToDraft
      }
    });
  };

  const updateQuestionFlowByProgramId = async (programId: string) => {
    if (!activeApplication) throw new Error('Cannot update question flow with no active application.');

    const { data } = await getQuestionFlowFromProgramId({ variables: { program_id: programId } });
    const newQuestionFlowId = data?.program[0].af_question_flows[0].id;

    if (!newQuestionFlowId) throw new Error('New question flow not found on program selected');

    await updateQuestionFlowMutation({
      variables: {
        application_id: activeApplication.id,
        new_question_flow_id: newQuestionFlowId
      }
    });
  };

  const submitActiveApplication = async (responses: IQuestionResponse[]) => {
    if (!activeApplication) throw new Error('Cannot submit with no active application.');
    const sourceQuestion = responses.find((q) => q.questionId === process.env.NEXT_PUBLIC_SOURCE_QUESTION_ID);
    const sourceResponse = sourceQuestion?.response as ISingleSelectAnswer | undefined;
    const phoneNumberQuestion = responses.find(
      (q) => q.questionId === process.env.NEXT_PUBLIC_PHONE_NUMBER_QUESTION_ID
    );
    const phoneNumberResponse = phoneNumberQuestion?.response as IPhoneNumberAnswer | undefined;

    await submitApplicationToGreenhouse({
      variables: {
        application_id: activeApplication.id
      }
    });

    await submitApplication({
      variables: {
        applicationId: activeApplication.id,
        dateSubmitted: new Date().toISOString(),
        source: (sourceResponse?.selectedOptionText as string) || null,
        phoneNumber: (phoneNumberResponse?.phoneNumber as string) || null
      }
    });
  };

  const deleteActiveApplication = async () => {
    if (isGetUserApplicationsLoading && !activeApplication) return;
    if (isCurrentUserLoading && !currentUser) return;
    if (!activeApplication) throw new Error('Cannot delete with no active application.');

    const { data } = await deleteApplicationMutation({
      variables: {
        application_id: activeApplication.id
      }
    });

    if (!data) {
      throw new Error('Failed to delete application application.');
    } else {
      // this feature to be removed / improved once we have the new webflow website
      Cookies.remove('hasAFApplication', { domain: '.antler.co' });
    }
  };

  const transferApplicationToGreenhouseJob = async (selectedProgramId: string) => {
    if (!activeApplication) throw new Error('Cannot transfer jobs with no active application');

    await transferApplicationToGreenhouseJobMutation({
      variables: {
        selectedProgramId: selectedProgramId,
        applicationId: activeApplication.id
      }
    });
  };

  return {
    allApplications,
    isApplicationsLoading,
    activeApplication,
    submittedApplications,
    greenhouseStatusMap,
    hasExistingDraftApplication,
    canSubmitNewApplication,
    isGreenhouseStatusLoading,
    createNewApplication,
    updateQuestionHistory,
    updateQuestionFlowByProgramId,
    submitActiveApplication,
    deleteActiveApplication,
    createGreenhouseCandidateAndApplication,
    createGreenhouseApplicationOnCandidate,
    transferApplicationToGreenhouseJob
  };
};

/************************************************
 * Types & Interfaces
 ************************************************/

interface IApplicationReturnData {
  id: string;
  primary_application_id: string | null;
  greenhouse_application_id: string | null;
  questions_history: string[];
  question_flow_id: string;
  date_submitted: string | null;
  af_question_flow: {
    program: {
      name: string;
      upcoming_cohort_date: string | null;
      translation: {
        translation_id: string;
        type: string;
      }[];
      location: {
        name: string;
        translation: {
          translation_id: string;
        };
      };
    };
  };
  updated_at: string;
  created_at: string;
}

/************************************************
 * GraphQL Queries & Mutations
 ************************************************/

const GET_USER_APPLICATIONS = gql`
  query GET_USER_APPLICATIONS($userId: String!) {
    af_application(order_by: { created_at: asc }, where: { user_id: { _eq: $userId } }, limit: 10) {
      id
      questions_history
      primary_application_id
      question_flow_id
      date_submitted
      greenhouse_application_id
      updated_at
      created_at
      af_question_flow {
        program {
          id
          location {
            id
            name
            translation {
              translation_id
            }
          }
          name
          upcoming_cohort_date
          translation {
            translation_id
            type
          }
        }
      }
    }
  }
`;

interface IInsertNewApplicationVars {
  questionFlowId: string;
}
interface IInsertNewApplicationData {
  insert_af_application_one: IApplicationReturnData;
}
const INSERT_NEW_APPLICATION = gql`
  mutation INSERT_NEW_APPLICATION($questionFlowId: uuid!) {
    insert_af_application_one(object: { question_flow_id: $questionFlowId }) {
      id
      questions_history
      primary_application_id
      question_flow_id
      date_submitted
      greenhouse_application_id
      af_question_flow {
        program {
          location {
            name
          }
          name
        }
      }
      updated_at
      created_at
    }
  }
`;

const SUBMIT_APPLICATION = gql`
  mutation SubmitApplication(
    $applicationId: uuid!
    $dateSubmitted: timestamptz!
    $source: String
    $phoneNumber: String
  ) {
    update_af_application_by_pk(
      pk_columns: { id: $applicationId }
      _set: { date_submitted: $dateSubmitted, source: $source, phone_number: $phoneNumber }
    ) {
      id
      questions_history
      date_submitted
      source
      phone_number
    }
    insert_user_contact_info(
      objects: { phone_number: $phoneNumber }
      on_conflict: { constraint: user_contact_info_pkey, update_columns: [phone_number] }
    ) {
      affected_rows
    }
  }
`;

const SUBMIT_APPLICATION_TO_GREENHOUSE = gql`
  mutation SubmitApplicationToGreenhouse($application_id: uuid!) {
    submitApplicationToGreenhouse(application_id: $application_id) {
      message
    }
  }
`;

interface IGetGreenHouseStatusVars {
  applicationId: string;
}
interface IGetGreenHouseStatusData {
  getGreenhouseStatus: {
    status: GreenhouseStatus | null;
  };
}
export type GreenhouseStatus = 'Awaiting Review' | 'Reviewing' | 'Rejected' | 'Approved';
const GET_GH_STATUS = gql`
  query GetGreenhouseStatus($applicationId: String!) {
    getGreenhouseStatus(applicationId: $applicationId) {
      status
    }
  }
`;

const UPDATE_APPLICATION_QUESTION_HISTORY = gql`
  mutation UpdateApplicationQuestionHistory(
    $applicationId: uuid!
    $questionsHistory: jsonb!
    $draftQuestionIDs: [uuid!]
  ) {
    update_af_application_by_pk(pk_columns: { id: $applicationId }, _set: { questions_history: $questionsHistory }) {
      id
      questions_history
    }
    update_af_question_response(
      where: { question_id: { _in: $draftQuestionIDs }, application_id: { _eq: $applicationId } }
      _set: { is_draft: true }
    ) {
      affected_rows
    }
  }
`;

const UPDATE_APPLICATION_QUESTION_FLOW = gql`
  mutation UpdateApplicationQuestionFlow($application_id: uuid!, $new_question_flow_id: uuid!) {
    update_af_application_by_pk(
      pk_columns: { id: $application_id }
      _set: { question_flow_id: $new_question_flow_id }
    ) {
      id
      questions_history
      primary_application_id
      question_flow_id
      date_submitted
      greenhouse_application_id
      updated_at
      created_at
      af_question_flow {
        program {
          id
          location {
            id
            name
            translation {
              translation_id
            }
          }
          name
          upcoming_cohort_date
          translation {
            translation_id
            type
          }
        }
      }
    }
  }
`;

interface IDeleteApplicationVars {
  application_id: string;
}
interface IDeleteApplicationData {
  application_id: string;
}
const DELETE_APPLICATION = gql`
  mutation DELETE_APPLICATION($application_id: uuid!) {
    delete_af_application_by_pk(id: $application_id) {
      id
    }
  }
`;

const CREATE_GH_CANDIDATE_AND_APPLICATION = gql`
  mutation CreateGHCandidateAndApplication($applicationId: String!, $firstName: String!, $lastName: String!) {
    createGHCandidateAndApplication(applicationId: $applicationId, firstName: $firstName, lastName: $lastName) {
      message
    }
  }
`;

const CREATE_GH_APPLICATION_ON_CANDIDATE = gql`
  mutation CreateGHApplicationOnCandidate($applicationId: String!, $greenhouseCandidateId: String!) {
    createGHApplicationOnCandidate(applicationId: $applicationId, greenhouseCandidateId: $greenhouseCandidateId) {
      message
    }
  }
`;

const GET_QUESTION_FLOW_FROM_PROGRAM_ID = gql`
  query GetQuestionFlowFromProgramId($program_id: uuid!) {
    program(where: { id: { _eq: $program_id } }) {
      is_disabled
      is_archived
      af_question_flows(where: { type: { _eq: "primary_founder" } }) {
        id
      }
    }
  }
`;

const TRANSFER_APPLICATION_TO_GREENHOUSE_JOB = gql`
  mutation TransferApplicationToGreenhouseJob($applicationId: String!, $selectedProgramId: String!) {
    transferApplicationToGreenhouseJob(applicationId: $applicationId, selectedProgramId: $selectedProgramId) {
      message
    }
  }
`;

/************************************************
 * Helper Functions
 ************************************************/

export const mapApplicationRow = (appRow: Get_User_ApplicationsQuery['af_application'][number]): IApplication => {
  return {
    id: appRow.id,
    questionsHistory: appRow.questions_history,
    primaryApplicationId: appRow.primary_application_id || undefined,
    greenhouseApplicationId: appRow.greenhouse_application_id || undefined,
    questionFlowId: appRow.question_flow_id,
    dateSubmitted: appRow.date_submitted ? new Date(appRow.date_submitted) : undefined,
    isCofounderApplication: !!appRow.primary_application_id,
    isSubmitted: !!appRow.date_submitted,
    programName: appRow.af_question_flow.program.name,
    upcomingCohortDate: appRow.af_question_flow.program.upcoming_cohort_date || undefined,
    location: appRow.af_question_flow.program.location?.name || '',
    updatedAt: new Date(appRow.updated_at),
    createdAt: new Date(appRow.created_at),
    locationTranslation: appRow.af_question_flow?.program.location?.translation?.translation_id ?? '',
    programTranslation:
      appRow.af_question_flow?.program.translation?.find((translation) => {
        return translation.type === 'NAME';
      })?.translation_id ?? '',
    programId: appRow.af_question_flow.program.id || '',
    locationId: appRow.af_question_flow.program.location?.id || ''
  };
};
