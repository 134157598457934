import { RequestPaths } from 'application-flow/enums/api/requestPaths';
import { IGetGreenhouseStatus } from 'application-flow/interfaces/greenhouse/IGetGreenhouseStatus';
import { getAxiosInstance } from 'application-flow/services/api/getAxiosInstance';
import { getBaseUrl } from 'application-flow/services/api/getBaseUrl';
import { generatePath } from 'react-router-dom';

/**
 * Gets an applicant's Greenhouse status
 * @param applicationId The applicationId of the applicant
 * @returns
 */
export const getGreenhouseStatus = async (applicationId: string): Promise<IGetGreenhouseStatus> => {
  const baseUrl = getBaseUrl(RequestPaths.GetApplicationGreenhouseStatus);
  const axiosInstance = await getAxiosInstance(baseUrl);
  const responseData: Promise<IGetGreenhouseStatus> = (
    await axiosInstance.get(generatePath(RequestPaths.GetApplicationGreenhouseStatus, { applicationId }))
  ).data;
  return responseData;
};
