import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import React, { createContext, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IVisaConfirmationDialogProps {
  onConfirmation: () => void;
}

export const VisaConfirmationDialog = ({ onConfirmation }: IVisaConfirmationDialogProps) => {
  const { isVisaDialogOpen, setIsVisaDialogOpen, visaRequirements, setVisaRequirements } = useVisaConfirmationDialog();
  const changeAnswerRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const handleClose = () => setIsVisaDialogOpen(false);
  const handleConfirmation = () => {
    setIsVisaDialogOpen(false);
    setVisaRequirements([]);
    onConfirmation();
  };

  return (
    <AlertDialog isOpen={isVisaDialogOpen} leastDestructiveRef={changeAnswerRef} onClose={handleClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('VisaConfirmationDialog_visaRequiredForThisLocation', 'Visa required for this location')}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text color="neutral.700">
              {t(
                'VisaConfirmationDialog_youMustMeetTheFollowingRequirements',
                'You must meet the following requirements to apply for this location:'
              )}
            </Text>
            <UnorderedList mt="2" ml="10">
              {visaRequirements?.map((requirement, index) => (
                <ListItem key={index} fontWeight="semibold">
                  {requirement}
                </ListItem>
              ))}
            </UnorderedList>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button variant="ghost" colorScheme="neutral" ref={changeAnswerRef} onClick={handleClose}>
              {t('VisaConfirmationDialog_Cancel', 'Cancel')}
            </Button>
            <Button variant="solid" colorScheme="primary" type="submit" ml={3} onClick={handleConfirmation}>
              {t('VisaConfirmationDialog_Proceed', 'Proceed')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

interface IVisaConfirmationDialogContext {
  isVisaDialogOpen: boolean;
  setIsVisaDialogOpen: (isOpen: boolean) => void;
  visaRequirements: string[];
  setVisaRequirements: (visaRequirements: string[]) => void;
}

const VisaConfirmationDialogContext = createContext<IVisaConfirmationDialogContext>(
  {} as IVisaConfirmationDialogContext
);

export const useVisaConfirmationDialog = () => {
  return useContext(VisaConfirmationDialogContext);
};

export const VisaConfirmationDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [visaRequirements, setVisaRequirements] = useState<string[]>([]);
  const [isVisaDialogOpen, setIsVisaDialogOpen] = useState(false);

  const providerValue: IVisaConfirmationDialogContext = {
    isVisaDialogOpen,
    setIsVisaDialogOpen,
    visaRequirements,
    setVisaRequirements
  };

  return (
    <VisaConfirmationDialogContext.Provider value={providerValue}>{children}</VisaConfirmationDialogContext.Provider>
  );
};
