import { gql, useApolloClient } from '@apollo/client';
import { useAuthContext } from 'application-flow/contexts/AuthContext';
import { useEffect } from 'react';

export default function EmailVerificationCheck() {
  const { currentUser, currentUserToken } = useAuthContext();
  const client = useApolloClient();

  useEffect(() => {
    if (!currentUser || !currentUserToken) return;

    if (!currentUser.emailVerified && !currentUserToken.claims.initialVerificationEmailSent) {
      client
        .mutate({ mutation: SEND_VERIFICATION_EMAIL, variables: { product: 'application-flow' } })
        .catch((err: Error) => {
          console.error(err);
        });
    }
  }, [currentUser, currentUserToken]);

  return null;
}

const SEND_VERIFICATION_EMAIL = gql`
  mutation SendVerificationEmail($product: String!) {
    sendVerificationEmail(product: $product) {
      message
    }
  }
`;
