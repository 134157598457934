import { localeOptions } from 'application-flow/components/LanguagePicker';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageDetector() {
  const { locale } = useRouter();
  const { i18n } = useTranslation();

  useEffect(() => {
    const ans = localeOptions.find((option) => {
      return option.value === locale;
    });

    if (ans && i18n.isInitialized) {
      i18n.changeLanguage(locale);
      if (locale === 'en-us') {
        dayjs.locale('en');
      } else {
        dayjs.locale(locale);
      }
    }
  }, [locale, i18n.isInitialized, i18n]);

  return null;
}
