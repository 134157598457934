import { ApolloError, gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'application-flow/contexts/AuthContext';
import { GetUserByIdQuery, GetUserByIdQueryVariables } from 'application-flow/generated/graphql';
import { IUser } from 'application-flow/types/user.types';

interface IUseCurrentUser {
  isCurrentUserLoading: boolean;
  currentUser?: IUser;
  error?: ApolloError;
}

export const useCurrentUser = (): IUseCurrentUser => {
  const { currentUser: authUser, isAuthLoading } = useAuthContext();
  const {
    data: getUserByIdData,
    loading: isGetUserByIdLoading,
    error
  } = useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GET_USER_BY_ID, {
    skip: !authUser,
    variables: { userId: authUser?.uid as string }
  });

  return {
    isCurrentUserLoading: isAuthLoading || isGetUserByIdLoading,
    currentUser: getUserByIdData && mapUserRow(getUserByIdData.user_by_pk),
    error
  };
};

const GET_USER_BY_ID = gql`
  query GetUserById($userId: String!) {
    user_by_pk(id: $userId) {
      id
      first_name
      last_name
      resume_url
      resume_filename
      greenhouse_candidate_id
      linkedin_url
      updated_at
      created_at
    }
  }
`;

const mapUserRow = (userRow: GetUserByIdQuery['user_by_pk']): IUser => {
  return {
    id: userRow?.id!,
    firstName: userRow?.first_name || undefined,
    lastName: userRow?.last_name || undefined,
    linkedin: userRow?.linkedin_url || undefined,
    ghCandidateId: userRow?.greenhouse_candidate_id || undefined,
    resume:
      userRow?.resume_url && userRow?.resume_filename
        ? {
            url: userRow.resume_url,
            fileName: userRow.resume_filename
          }
        : undefined
  };
};
