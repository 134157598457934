import { auth } from 'antler-one';
import dayjs from 'dayjs';
import {
  AuthProvider as FirebaseAuthProvider,
  createUserWithEmailAndPassword,
  IdTokenResult,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
  UserCredential
} from 'firebase/auth';
import posthog from 'posthog-js';
import { createContext, useContext, useEffect, useState } from 'react';

interface AuthContextInterface {
  currentUser: User | null;
  currentUserToken: IdTokenResult | null;
  isAuthLoading: boolean;
  loginWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  signUpWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  signInWithOAuthProvider: (provider: FirebaseAuthProvider) => Promise<UserCredential>;
  initiatePasswordReset: (email: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

export const useAuthContext = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserToken, setCurrentUserToken] = useState<IdTokenResult | null>(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  /**
   * Signs a user in with email and password
   * @param {string} email the email of the user who wants to login
   * @param {string} password the password of the user who wants to login
   * @returns firebase user credentials
   */
  const loginWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  /**
   * Signs up a user with email and password
   * @param {string} email the email of the user who wants to sign up
   * @param {string} password the password of the user who wants to sign up
   * @returns firebase user credentials
   */
  const signUpWithEmailAndPassword = (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  // Sign in with any firebase social sign in (google, github, etc)
  const signInWithOAuthProvider = async (provider: FirebaseAuthProvider) => {
    return await signInWithPopup(auth, provider);
  };

  /**
   * Send the email to reset the password
   * @param {string} email the email of the user who wants to reset their password
   * @returns success or failure
   */
  const initiatePasswordReset = (email: string) => {
    return sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    if (!currentUserToken || !currentUser) return;

    const tokenExpirationDateTime = dayjs(currentUserToken.expirationTime);
    const timeToTokenExpiryInMs = tokenExpirationDateTime.diff(dayjs());

    const refreshTokenTimeout = setTimeout(async () => {
      const tokenResult = await currentUser.getIdTokenResult(true);
      setCurrentUserToken(tokenResult);
    }, timeToTokenExpiryInMs);

    return () => clearTimeout(refreshTokenTimeout);
  }, [currentUserToken]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      // When the auth state changes with the user
      if (user) {
        setIsAuthLoading(true);
        const tokenResult = await user.getIdTokenResult(true);
        const hasuraClaims = tokenResult.claims['https://hasura.io/jwt/claims'];

        // User does not have Hasura custom claims set, log them out
        if (!hasuraClaims) {
          signOut(auth);
          return;
        } else {
          // Users have Hasura custom claims set, set auth state
          setCurrentUser(user);
          setCurrentUserToken(tokenResult);

          if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
            posthog.identify(user.uid);
            posthog.capture('set user props on login', {
              $set: { email: user.email }
            });
          }

          setIsAuthLoading(false);
        }
      } else {
        // User logged out so clear auth state
        setCurrentUserToken(null);
        setCurrentUser(null);
        setIsAuthLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  const providerValue: AuthContextInterface = {
    currentUser,
    currentUserToken,
    isAuthLoading,
    loginWithEmailAndPassword,
    signUpWithEmailAndPassword,
    signInWithOAuthProvider,
    initiatePasswordReset
  };

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};
