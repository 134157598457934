import { useVisaConfirmationDialog } from 'application-flow/components/VisaConfirmationDialog';
import { DEFAULT_PROGRAM_ID, useApplications } from 'application-flow/hooks/useApplications';
import { useCurrentUser } from 'application-flow/hooks/useCurrentUser';
import { useQuestions } from 'application-flow/hooks/useQuestions';
import { useResponses } from 'application-flow/hooks/useResponses';
import {
  IAnswer,
  ICityAnswer,
  ICountrySelectAnswer,
  IDropdownAnswer,
  IFullNameAnswer,
  ILinkedInAnswer,
  ILocationAnswer,
  IProgramAnswer,
  IQuestionResponse,
  IQuestionRule,
  ISingleSelectAnswer,
  ITextAnswer,
  type IQuestion
} from 'application-flow/types/questions.types';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';

interface IApplicationContext {
  currentQuestion: IQuestion | undefined;
  currentAnswer: IAnswer;
  validator: yup.ObjectSchema<any> | null;
  error: yup.ValidationError | Error | null;
  isAnswerEmpty: boolean;
  isSubmitting: boolean;
  isQuestionPrefilling: boolean;
  isApplicationContextLoading: boolean;
  isNavigateBackDisabled: boolean;
  completionOutOf100: number;
  setCurrentQuestion: (currentQuestion: IQuestion | undefined) => void;
  setAnswer: (answer: IAnswer) => void;
  setValidator: (validator: yup.ObjectSchema<any> | null) => void;
  setError: (error: yup.ValidationError | Error | null) => void;
  submitCurrentQuestion: () => void;
  navigateToPrevQuestion: () => void;
  validateCurrentQuestion: (path?: string) => Promise<boolean>;
  isLastQuestionInFlow: (question: IQuestion) => boolean;
}

export const ApplicationContext = React.createContext<IApplicationContext>({} as IApplicationContext);

export const useApplicationContext = () => useContext(ApplicationContext);

export function ApplicationContextProvider({ children }: { children: React.ReactElement }) {
  const router = useRouter();
  const {
    activeApplication,
    isApplicationsLoading,
    updateQuestionFlowByProgramId,
    createGreenhouseCandidateAndApplication,
    createGreenhouseApplicationOnCandidate,
    transferApplicationToGreenhouseJob
  } = useApplications();
  const { questions, isQuestionsLoading } = useQuestions(activeApplication?.questionFlowId);
  const { responses, isResponsesLoading, numberOfNonDraftResponses, findResponseByQuestionId, upsertResponse } =
    useResponses(activeApplication?.id);
  const { isVisaDialogOpen, setIsVisaDialogOpen, visaRequirements } = useVisaConfirmationDialog();
  const { currentUser } = useCurrentUser();

  const [currentQuestion, setCurrentQuestion] = useState<IQuestion>();
  const [answer, setAnswer] = useState<IAnswer>({} as IAnswer);
  const [validator, setValidator] = useState<yup.ObjectSchema<any> | null>(null);
  const [error, setError] = useState<yup.ValidationError | Error | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [isQuestionPrefilling, setIsQuestionPrefilling] = useState(true);

  const isApplicationContextLoading = isQuestionsLoading || isApplicationsLoading || isResponsesLoading;
  const isNavigateBackDisabled = !!activeApplication && activeApplication.questionsHistory.length <= 1;
  const completionOutOf100 =
    !isApplicationContextLoading && numberOfNonDraftResponses > 0
      ? (Math.log(numberOfNonDraftResponses + 0.1) / Math.log(questions.filter((q) => !q.isDisabled).length)) * 100
      : 0;

  const answerKeysWithValues = Object.keys(answer).filter((key) => {
    const value = (answer as Record<string, any>)[key];
    if (Array.isArray(value) || typeof value === 'string') return value.length > 0;
    return value !== undefined;
  });
  const isAnswerEmpty = answerKeysWithValues.length === 0;

  // Reset the error object every time the answer object is set to a new value
  useEffect(() => {
    setError(null);
  }, [answer]);

  // Set the current question to the first question or latest answered one in the flow
  useEffect(() => {
    if (isApplicationContextLoading || !questions.length || !activeApplication) return;
    //const currentQuestionId = activeApplication.questionsHistory.at(-1) || questions.find(({ isFirst }) => isFirst)?.id;
    const currentQuestionId =
      activeApplication.questionsHistory.slice(-1)[0] || questions.find(({ isFirst }) => isFirst)?.id; // Above does not work on old safari
    const currentQuestion = currentQuestionId ? questions.find(({ id }) => currentQuestionId === id) : undefined;
    if (!currentQuestion) throw new Error('Application current question not found in question flow.');
    setCurrentQuestion(currentQuestion);
  }, [isApplicationContextLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // Prefill current question answer
  useEffect(() => {
    if (activeApplication && currentQuestion && !isApplicationContextLoading) {
      setIsQuestionPrefilling(true);
      //const currentQuestionId = activeApplication.questionsHistory.at(-1);
      const currentQuestionId = activeApplication.questionsHistory.slice(-1)[0]; // Above does not work on old safari
      const currentQuestionResponse = findResponseByQuestionId(currentQuestionId);
      if (currentQuestionResponse) setAnswer(currentQuestionResponse.response);
      setIsQuestionPrefilling(false);
    }
  }, [isApplicationContextLoading, currentQuestion]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateCurrentQuestion = async () => {
    if (!currentQuestion) throw new Error('Validation attempt with no current question.');
    if (!activeApplication) throw new Error('Validation attempt with no current active application.');

    // Validate the answer, stop submission if invalid
    try {
      if (validator) {
        if (currentQuestion.type !== 'cofounderInvite') {
          await validator.validate(answer);
        } else {
          await validator.validate(answer, { abortEarly: false });
        }
        return true;
      } else return true;
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setError(error);
        return false;
      } else {
        throw error;
      }
    }
  };

  const submitCurrentQuestion = async () => {
    if (!currentQuestion) throw new Error('Submission attempt with no current question.');
    if (!activeApplication) throw new Error('Submission attempt with no current active application.');
    setIsSubmitting(true);

    // Validate the answer, stop submission if invalid
    if (!(await validateCurrentQuestion())) {
      setIsSubmitting(false);
      return;
    }

    // Confirm visa requirements if current question is a location question
    if (currentQuestion.type === 'location' && !isVisaDialogOpen && visaRequirements.length) {
      setIsVisaDialogOpen(true);
      setIsSubmitting(false);
      return;
    }

    // Change the application's flow if program question is submitted
    // NOTE: This works only if all previous questions before the program question are the same in EVERY flow

    if (currentQuestion.type === 'program') {
      const chosenProgramId = (answer as IProgramAnswer).programId;

      try {
        if (!chosenProgramId)
          throw new Error(
            'No program ID has been selected on program question. Validator should not allow this to happen.'
          );

        if (!activeApplication.isCofounderApplication) {
          await transferApplicationToGreenhouseJob(chosenProgramId);
        }

        await updateQuestionFlowByProgramId(chosenProgramId);
      } catch (err) {
        console.error(err);
        setError(new Error('Error submitting response. Please try again later.'));
        setIsSubmitting(false);
        return;
      }
    }

    if (currentQuestion.type === 'location') {
      const chosenLocation = (answer as ILocationAnswer).locationId;
      if (!chosenLocation) {
        await updateQuestionFlowByProgramId(DEFAULT_PROGRAM_ID);
      }
    }

    // Create application on GH once we have first and last name, and a greenhouse application id has not been assigned to the application
    // Co-founder applications do not need to be created separately in GH, but rather attached to the primary applicant PDF
    if (
      currentQuestion.type === 'fullname' &&
      !activeApplication.greenhouseApplicationId &&
      !activeApplication.isCofounderApplication
    ) {
      const { firstName, lastName } = answer as IFullNameAnswer;

      // Create application either on existing candidate or new candidate id in Greenhouse
      try {
        if (currentUser?.ghCandidateId) {
          await createGreenhouseApplicationOnCandidate({ greenhouseCandidateId: currentUser.ghCandidateId });
        } else {
          await createGreenhouseCandidateAndApplication({ firstName, lastName });
        }
      } catch (err) {
        console.error(err);
        setError(new Error('Error submitting response. Please try again later.'));
        setIsSubmitting(false);
        return;
      }
    }

    // Determine next question in the flow if any
    const { nextQuestion, validRule } =
      determineNextQuestion({ currentQuestion, currentAnswer: answer, questions, responses }) || {};

    // handle config of the selected options
    if (validRule?.config?.redirectToAcademy) {
      // await submitActiveApplication();
      router.push('/?redirectToAcademy=true');
      return;
    }

    // Submit the answer of the question
    try {
      const updatedQuestionsHistory = [...activeApplication.questionsHistory];

      if (!updatedQuestionsHistory.length) updatedQuestionsHistory.push(currentQuestion.id); // If first question, add current question to history
      if (nextQuestion) updatedQuestionsHistory.push(nextQuestion.id);

      await upsertResponse({
        questionId: currentQuestion.id,
        answer,
        updatedQuestionsHistory,
        isDraft: false,
        questionTitle: currentQuestion.title
      });

      // Go to review page if no next question
      if (!nextQuestion) {
        setIsSubmitting(false); // Loading state will remain when editing from review page if not set to false here
        posthog.capture('user entered review section', {
          location_review: activeApplication.location,
          program_review: activeApplication.programName
        });
        router.push('/review');
        return;
      }

      const { response } = findResponseByQuestionId(nextQuestion?.id) || {};

      setError(null);
      setCurrentQuestion(nextQuestion);
      setAnswer((response || {}) as IAnswer);
    } catch (err) {
      console.log('Hasura mutation error on question submit', err);
      setError(new Error('Error submitting response. Please try again later.'));
    }

    setValidator(null);
    setIsSubmitting(false);
  };

  const navigateToPrevQuestion = async () => {
    try {
      if (!activeApplication || !currentQuestion) throw new Error('No active application or current question found');
      // Update the application question history
      const updatedQuestionsHistory = [...activeApplication.questionsHistory];
      updatedQuestionsHistory.pop();

      //const previousQuestionId = updatedQuestionsHistory.at(-1);
      const previousQuestionId = updatedQuestionsHistory.slice(-1)[0]; // Above does not work on old safari
      const previousQuestion = questions.find(({ id }) => id === previousQuestionId);

      await upsertResponse({
        questionId: currentQuestion.id,
        answer,
        updatedQuestionsHistory,
        isDraft: true,
        questionTitle: currentQuestion.title
      });

      // Prefill the response
      const { response } = findResponseByQuestionId(previousQuestion?.id) || {};

      setCurrentQuestion(previousQuestion);
      setError(null);
      setAnswer((response || {}) as IAnswer);
    } catch (err) {
      console.log('error', err);
      setError(new Error('Unable to navigate to previous question - please try again later.'));
    }
  };

  const isLastQuestionInFlow = (question: IQuestion) => {
    const { nextQuestion } =
      determineNextQuestion({ currentQuestion: question, currentAnswer: answer, questions, responses }) || {};
    return !nextQuestion;
  };

  const providerValue: IApplicationContext = {
    currentQuestion,
    setCurrentQuestion,
    currentAnswer: answer,
    setAnswer,
    validator,
    setValidator,
    error,
    setError,
    isAnswerEmpty,
    isSubmitting,
    isApplicationContextLoading,
    isQuestionPrefilling,
    isNavigateBackDisabled,
    navigateToPrevQuestion,
    submitCurrentQuestion,
    completionOutOf100,
    validateCurrentQuestion,
    isLastQuestionInFlow
  };

  return <ApplicationContext.Provider value={providerValue}>{children}</ApplicationContext.Provider>;
}

interface IDetermineNextQuestionProps {
  currentQuestion: IQuestion;
  currentAnswer?: IAnswer;
  questions: IQuestion[];
  responses: IQuestionResponse[];
}
interface IDetermineNextQuestionResult {
  nextQuestion?: IQuestion;
  validRule?: IQuestionRule;
}
/**
 * Determines the next question in the flow based on attached rules.
 * If no next question returns undefined, indicating the end of the flow.
 * Returns matched valid rule in case it has extra config like redirect to academy after end of flow.
 */
export const determineNextQuestion = ({
  currentQuestion,
  currentAnswer,
  questions,
  responses
}: IDetermineNextQuestionProps): IDetermineNextQuestionResult | undefined => {
  if (!currentQuestion.rules.length) return undefined; // Last question of flow/branch won't have any rules to end the application

  const defaultRules = currentQuestion.rules.filter((rule) => !rule.questionIdToCheck);
  const conditionalRules = currentQuestion.rules.filter((rule) => rule.questionIdToCheck);
  const defaultRule = defaultRules[0] as IQuestionRule | undefined;

  if (!defaultRules.length && (currentQuestion.isDisabled || !currentQuestion.isRequired))
    throw new Error('Optional/disabled question has no default rule (rule with questionIdToCheck = null).');
  if (defaultRules.length > 1)
    throw new Error('Question has more than one default rule (rule with questionIdToCheck = null).');

  const validRules = conditionalRules.filter((rule) => {
    const questionToCheck = questions.find((question) => question.id === rule.questionIdToCheck);
    if (!questionToCheck)
      throw new Error(`Question to check with id ${rule.questionIdToCheck} not found in questions array.`);

    let answer: IAnswer | undefined;
    if (currentQuestion.id === rule.questionIdToCheck) {
      answer = currentAnswer;
    } else {
      const questionResponse = responses.find((response) => response.questionId === questionToCheck.id);
      if (!questionResponse)
        throw new Error(`Response for question to check with id ${questionToCheck.id} not found in responses array.`);
      answer = questionResponse.response;
    }

    if (!answer) return false;

    switch (questionToCheck.type) {
      case 'fullname':
        const answerFullname = answer as IFullNameAnswer;
        return `${answerFullname.firstName} ${answerFullname.lastName}` === rule.targetAnswer;
      case 'text':
        const answerText = answer as ITextAnswer;
        return answerText.text === rule.targetAnswer;
      case 'upload':
        throw new Error('Rule checks answer of upload question type not supported yet.');
      case 'cofounderInvite':
        throw new Error('Rule checks answer of Cofounder invite question type not supported yet.');
      case 'program':
        const answerProgram = answer as IProgramAnswer;
        return answerProgram.programId === rule.targetAnswer;
      case 'location':
        const answerLocation = answer as ILocationAnswer;
        return answerLocation.locationId === rule.targetAnswer;
      case 'city':
        const answerCity = answer as ICityAnswer;
        return answerCity.cityId === rule.targetAnswer;
      case 'linkedin':
        const answerLinkedin = answer as ILinkedInAnswer;
        return answerLinkedin.linkedin === rule.targetAnswer;
      case 'singleselect':
        const answerSingleSelect = answer as ISingleSelectAnswer;
        return answerSingleSelect.selectedOptionId === rule.targetAnswer;
      case 'dropdown':
        const answerDropdown = answer as IDropdownAnswer;
        return answerDropdown.selectedOptionId === rule.targetAnswer;
      case 'countrySelect':
        const answerCountrySelect = answer as ICountrySelectAnswer;
        return (answerCountrySelect.countryName = rule.targetAnswer);
      default:
        throw new Error(`Rule with unsupported question type not supported yet.`);
    }
  });

  if (!validRules.length && !defaultRule) throw new Error('No valid rules found and no default rule found.');
  if (validRules.length > 1) throw new Error('More than one valid rule found for question.');

  let nextQuestion: IQuestion | undefined;
  let validRule: IQuestionRule | undefined;

  if (validRules.length) {
    validRule = validRules[0];
    nextQuestion = questions.find((question) => question.id === validRules[0].nextQuestionId);
  } else if (defaultRule) {
    validRule = defaultRule;
    nextQuestion = questions.find((question) => question.id === defaultRule.nextQuestionId);
  }

  // If question is disabled, go to the next question
  if (nextQuestion && nextQuestion.isDisabled) {
    return determineNextQuestion({ currentQuestion: nextQuestion, questions, responses });
  }

  return { nextQuestion, validRule };
};
