import { ChakraProvider } from '@chakra-ui/react';
import { theme } from 'antler-one';
import EmailVerificationCheck from 'application-flow/components/renderless/EmailVerificationCheck';
import LanguageDetector from 'application-flow/components/renderless/LanguageDetector';
import { VisaConfirmationDialogProvider } from 'application-flow/components/VisaConfirmationDialog';
import { ApolloClientProvider } from 'application-flow/contexts/ApolloContext';
import { ApplicationContextProvider } from 'application-flow/contexts/ApplicationContext';
import { AuthProvider } from 'application-flow/contexts/AuthContext';
import 'application-flow/i18n/index';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import(`dayjs/locale/en`);
import(`dayjs/locale/ko`);
import(`dayjs/locale/ja`);
import(`dayjs/locale/pt-br`);

import Gleap from 'gleap';

function MyApp({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  useEffect(() => {
    window.addEventListener('error', (e) => {
      // To solve error where connection to indexed db lost, refresh page to fix
      // https://stackoverflow.com/questions/56496296/how-do-i-fix-firestore-sdk-hitting-an-internal-error-was-encountered-in-the-ind
      if (e.message.toLowerCase().includes('connection to indexed database server lost')) window.location.reload();
    });
  }, []);

  useEffect(() => {
    if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
      // Init PostHog
      posthog.init('phc_tSaEJXDnTlTdJggtmFmsvajd69DWBWpJaZwkJLiNtk9', {
        api_host: 'https://app.posthog.com',
        opt_in_site_apps: true
      });

      // Init Gleap
      Gleap.initialize('U4uE3uCAHZ1QxvqtqmNgRQHZFJxWpksw');
      if (location.hostname.includes('staging')) Gleap.setTags(['Staging']);
    }
  });

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="analyticsScript" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
          page_path: window.location.pathname,
          });
      `}
      </Script>
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=G-LXBPTWVYET`} />
      <Script id="analyticsScript" strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LXBPTWVYET', {
          page_path: window.location.pathname,
          });
      `}
      </Script>
      <LanguageDetector />
      <AuthProvider>
        <ApolloClientProvider>
          <QueryClientProvider client={queryClient}>
            <EmailVerificationCheck />
            <ChakraProvider theme={theme}>
              <VisaConfirmationDialogProvider>
                <ApplicationContextProvider>
                  <Component {...pageProps} />
                </ApplicationContextProvider>
              </VisaConfirmationDialogProvider>
            </ChakraProvider>
          </QueryClientProvider>
        </ApolloClientProvider>
      </AuthProvider>
    </>
  );
  // }
}

export default MyApp;
