import { auth } from 'antler-one';
import axios from 'axios';

export const getAxiosInstance = async (baseURL: string | undefined) => {
  if (!baseURL) {
    throw new Error('No baseURL defined for Axios instance.');
  }

  const axiosInstance = axios.create({
    baseURL
  });

  // If the token is about to expire, refresh it before the request is made.
  // Otherwise, use the existing token.
  axiosInstance.interceptors.request.use(async (config) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error('Unable to find existing user.');
    }

    const token = await currentUser.getIdToken(true);
    config.headers!.Authorization = `Bearer ${token}`;

    return config;
  });

  return axiosInstance;
};
