import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { GetAfQuestionDataQuery, GetAfQuestionDataQueryVariables } from 'application-flow/generated/graphql';
import {
  ICityQuestion,
  ICofounderInviteQuestion,
  ICountrySelectQuestion,
  IDropdownQuestion,
  IFullNameQuestion,
  ILinkedInQuestion,
  ILocationQuestion,
  IMultiSelectQuestion,
  IPhoneNumberQuestion,
  IProgramQuestion,
  IQuestion,
  IQuestionRule,
  IQuestionTranslation,
  ISingleSelectQuestion,
  ITextQuestion,
  IUploadQuestion
} from 'application-flow/types/questions.types';

interface IUseQuestions {
  questions: IQuestion[];
  isQuestionsLoading: boolean;
  refetchQuestions(variables: GetAfQuestionDataQueryVariables): Promise<ApolloQueryResult<GetAfQuestionDataQuery>>;
}

export const useQuestions = (questionFlowId?: string): IUseQuestions => {
  const {
    data: getQuestions,
    loading: isQuestionsLoading,
    refetch: refetchQuestions
  } = useQuery<GetAfQuestionDataQuery, GetAfQuestionDataQueryVariables>(GET_AF_QUESTION_DATA, {
    variables: { questionFlowId: questionFlowId as string },
    skip: !questionFlowId
  });

  const questions = getQuestions?.questions.map(mapHasuraQuestions) || [];

  return {
    questions,
    isQuestionsLoading,
    refetchQuestions
  };
};

/************************************************
 * GraphQL Queries & Mutations
 ************************************************/

export const GET_AF_QUESTION_DATA = gql`
  query GetAfQuestionData($questionFlowId: uuid!) {
    questions: af_question(where: { af_question_flow_questions: { question_flow_id: { _eq: $questionFlowId } } }) {
      id
      title
      type
      helper_text
      config
      af_question_translations {
        translation_id
        type
      }
      af_question_options(order_by: { sort_order: asc }) {
        id
        option_text
        helper_text
        sort_order
        af_question_option_translations {
          translation_id
          type
        }
      }
      af_question_rules(where: { question_flow_id: { _eq: $questionFlowId } }) {
        id
        next_question_id
        question_id_to_check
        target_answer
        config
      }
      af_question_flow_questions(where: { question_flow_id: { _eq: $questionFlowId } }) {
        is_required
        is_first
        is_disabled
        am_order
      }
      af_question_section {
        id
        name
      }
    }
  }
`;

/************************************************
 * Helper Functions
 ************************************************/

const mapQuestionRule = (
  rule: GetAfQuestionDataQuery['questions'][number]['af_question_rules'][number]
): IQuestionRule => ({
  id: rule.id,
  nextQuestionId: rule.next_question_id || undefined,
  questionIdToCheck: rule.question_id_to_check || undefined,
  targetAnswer: rule.target_answer,
  config: rule.config || {}
});

export const mapHasuraQuestions = (hasuraQuestion: GetAfQuestionDataQuery['questions'][number]): IQuestion => {
  const question: Partial<IQuestion> = {
    id: hasuraQuestion.id,
    title: hasuraQuestion.title,
    helperText: hasuraQuestion.helper_text,
    isRequired: hasuraQuestion.af_question_flow_questions[0].is_required,
    isDisabled: hasuraQuestion.af_question_flow_questions[0].is_disabled,
    isFirst: hasuraQuestion.af_question_flow_questions[0].is_first,
    section: hasuraQuestion.af_question_section?.name,
    rules: hasuraQuestion.af_question_rules.map(mapQuestionRule)
  };

  // process translations
  const questionTranslations: IQuestionTranslation = hasuraQuestion.af_question_translations.reduce(
    (previousValue, currentValue) => {
      return { ...previousValue, [currentValue.type]: currentValue.translation_id };
    },
    {}
  );

  question['translations'] = questionTranslations;

  switch (hasuraQuestion.type) {
    case 'city':
      return {
        ...question,
        type: 'city',
        city: {}
      } as ICityQuestion;
    case 'text':
      return {
        ...question,
        type: 'text',
        text: {
          ...(hasuraQuestion.config.text &&
            Object.keys(hasuraQuestion.config.text).includes('is_link') && {
              isLink: hasuraQuestion.config.text.is_link
            }),
          ...(hasuraQuestion.config.text &&
            Object.keys(hasuraQuestion.config.text).includes('is_text_area') && {
              isTextArea: hasuraQuestion.config.text.is_text_area
            }),
          ...(hasuraQuestion.config?.text?.placeholder && { placeholder: hasuraQuestion.config.text.placeholder }),
          ...(hasuraQuestion.config?.text?.min_characters && {
            minCharacters: hasuraQuestion.config.text.min_characters
          }),
          ...(hasuraQuestion.config?.text?.max_characters && {
            maxCharacters: hasuraQuestion.config.text.max_characters
          }),
          ...(hasuraQuestion.config?.text?.is_text_area && { isTextArea: hasuraQuestion.config.text.is_text_area })
        }
      } as ITextQuestion;
    case 'fullname':
      return {
        ...question,
        type: 'fullname',
        fullname: {}
      } as IFullNameQuestion;
    case 'singleselect':
      return {
        ...question,
        type: 'singleselect',
        singleselect: {
          options: hasuraQuestion.af_question_options.map((option) => ({
            id: option.id,
            text: option.option_text,
            helperText: option?.helper_text,
            sortOrder: option.sort_order,
            translations: option.af_question_option_translations.reduce((previousValue, currentValue) => {
              return { ...previousValue, [currentValue.type]: currentValue.translation_id };
            }, {})
          }))
        }
      } as ISingleSelectQuestion;
    case 'dropdown':
      return {
        ...question,
        type: 'dropdown',
        dropdown: {
          ...(hasuraQuestion.config?.dropdown?.placeholder && {
            placeholder: hasuraQuestion.config.dropdown.placeholder
          }),
          ...(hasuraQuestion.config?.dropdown?.is_searchable && {
            isSearchable: hasuraQuestion.config.dropdown.is_searchable
          }),
          options: hasuraQuestion.af_question_options.map((option) => ({
            id: option.id,
            text: option.option_text,
            helperText: option?.helper_text,
            sortOrder: option.sort_order,
            translations: option.af_question_option_translations.reduce((previousValue, currentValue) => {
              return { ...previousValue, [currentValue.type]: currentValue.translation_id };
            }, {})
          }))
        }
      } as IDropdownQuestion;
    case 'upload':
      return {
        ...question,
        type: 'upload',
        upload: {
          ...(hasuraQuestion.config?.upload?.file_types?.length && {
            fileTypes: hasuraQuestion.config?.upload?.file_types
          }),
          ...(hasuraQuestion.config?.upload?.max_size && {
            maxSize: parseFloat(hasuraQuestion.config?.upload?.max_size)
          }),
          ...(hasuraQuestion.config?.upload?.placeholder && { placeholder: hasuraQuestion.config?.upload?.placeholder })
        }
      } as IUploadQuestion;
    case 'linkedin':
      return {
        ...question,
        type: 'linkedin',
        linkedin: {}
      } as ILinkedInQuestion;
    case 'location':
      return {
        ...question,
        type: 'location',
        location: {
          ...hasuraQuestion.config?.location
        }
      } as ILocationQuestion;
    case 'program':
      return {
        ...question,
        type: 'program',
        program: {
          ...hasuraQuestion.config?.program
        }
      } as IProgramQuestion;
    case 'cofounderInvite':
      return {
        ...question,
        type: 'cofounderInvite',
        cofounderInvite: {}
      } as ICofounderInviteQuestion;
    case 'countrySelect':
      return {
        ...question,
        type: 'countrySelect',
        countrySelect: {}
      } as ICountrySelectQuestion;
    case 'multiselect':
      return {
        ...question,
        type: 'multiselect',
        multiselect: {
          options: hasuraQuestion.af_question_options.map((option) => ({
            id: option.id,
            text: option.option_text,
            helperText: option?.helper_text,
            sortOrder: option.sort_order,
            translations: option.af_question_option_translations.reduce((previousValue, currentValue) => {
              return { ...previousValue, [currentValue.type]: currentValue.translation_id };
            }, {})
          }))
        }
      } as IMultiSelectQuestion;
    case 'phoneNumber':
      return {
        ...question,
        type: 'phoneNumber',
        phoneNumber: {}
      } as IPhoneNumberQuestion;
    default:
      throw new Error(`Unknown question type: ${hasuraQuestion.type}`);
  }
};
