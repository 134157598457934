import { Box, Icon } from '@chakra-ui/react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { DropdownSelector, DropdownSelectorOptions } from 'antler-one';
import i18n from 'application-flow/i18n';
import { useRouter } from 'next/router';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';

export const localeOptions: DropdownSelectorOptions[] = [
  { label: 'EN', value: 'en-us', optionIcon: <FlagIcon code={'US' as FlagIconCode} width="30px" /> },
  { label: 'KO', value: 'ko', optionIcon: <FlagIcon code={'KR' as FlagIconCode} width="30px" /> },
  { label: 'JA', value: 'ja', optionIcon: <FlagIcon code={'JP' as FlagIconCode} width="30px" /> },
  { label: 'PT', value: 'pt-br', optionIcon: <FlagIcon code={'BR' as FlagIconCode} width="30px" /> }
];

export type LanguagePickerSizes = 'sm' | 'md' | 'lg';
export interface ILanguagePicker {
  size?: LanguagePickerSizes;
}

export function LanguagePicker({ size = 'md' }: ILanguagePicker) {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const onLanguageSelect = (option: DropdownSelectorOptions) => {
    i18n.changeLanguage(option.value);
    router.push({ pathname, query }, asPath, { locale: option.value });
  };

  return (
    <Box
      as={DropdownSelector}
      width="100px"
      onOptionSelect={onLanguageSelect}
      defaultSelection={i18n.language}
      dropdownOptions={localeOptions}
      selectorIcon={<Icon as={GlobeAltIcon} />}
      size={size}
    />
  );
}
