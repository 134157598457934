import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next.
  .use(HttpApi)
  .init({
    backend: {
      loadPath: '{{lng}}|{{ns}}',
      request: async (options, url, payload, callback) => {
        const lng = url.split('|')[0].toLowerCase();

        const gqlQuery = `
        query GetTranslation($_eq: String = "en") {
          translation_frontend(where: {project: {_eq: APPLICATION_FLOW}}) {
            id
            values(where: {language: {_eq: $_eq}}) {
              text
            }
          }
          translation_backend {
            id
            values(where: {language: {_eq: $_eq}}) {
              text
              language
            }
          }
        }
        `;

        try {
          const result = await fetch(process.env.NEXT_PUBLIC_HASURA_ENDPOINT as string, {
            method: 'POST',
            body: JSON.stringify({
              query: gqlQuery,
              variables: { _eq: lng },
              operationName: 'GetTranslation'
            })
          });

          const { data } = await result.json();
          const { translation_frontend, translation_backend } = data;

          const translations = [...translation_frontend, ...translation_backend];

          const cleanedTranslations = (translations as Array<any>).reduce((previousValue, currentValue) => {
            const id: string = currentValue.id;
            const text: string | undefined = currentValue.values[0]?.text;
            if (text) {
              return { ...previousValue, [id]: text };
            } else {
              return previousValue;
            }
          }, {});

          callback(null, {
            data: JSON.stringify(cleanedTranslations),
            status: 200
          });
        } catch (e) {
          callback(null, {
            data: '',
            status: 404
          });
          throw new Error(`${e}`);
        }
      }
    },
    react: {
      useSuspense: false
    },
    lng: 'en-us',
    fallbackLng: 'en-us',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
