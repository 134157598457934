import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getSignedToken } from 'antler-one';
import { useAuthContext } from './AuthContext';

interface ApolloClientProviderProps {
  children: React.ReactNode;
}

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_HASURA_ENDPOINT
});

const getAuthLink = (token?: string) =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${getSignedToken(token)}` } : { 'X-Hasura-Role': `anonymous` })
      }
    };
  });

const client = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV === 'development' || !!process.env.NEXT_PUBLIC_ENABLE_APOLLO_DEV_TOOL,
  cache: new InMemoryCache({})
});

/**
 * Provides an ApolloClient instance to its children configured and ready to communicate with Hasura.
 */
export const ApolloClientProvider = ({ children }: ApolloClientProviderProps) => {
  const { currentUserToken } = useAuthContext();

  client.setLink(getAuthLink(currentUserToken?.token).concat(httpLink));

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
